.tooltip-padding {
  padding: 0.5em 0;
}

.card {
  border-style: solid;
  border-width: 1px;
  border-color: #e1e1e1;
  padding: 16px;
  background-color: #ffffff;
}
.service-label {
  font-size: 15px;
}

.card:hover {
  box-shadow: 0 4px 8px 0 #cecece, 0 6px 10px 0 #cecece;
}
