.hero-image {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../../assets/hero-image.svg); */
    background-repeat: no-repeat;
    background-size: cover;
    position: center;
    height: 390px;
    max-width: 1440px;
    color: #fff;
    padding: 140px 40px 40px 40px;
  }
  