.title {
  font-family: Pluto Sans, SansSerif, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;

  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #191919;
}

.navItem {
  text-decoration: none;
  font-family: Pluto Sans, SansSerif, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  text-align: center;

  color: #191919;
}

.hamburger {
  text-decoration: none;
  border: none;
  background: #ffffff;
}

.icon-link {
  text-decoration: none;
}

.img-container {
  width: auto;
  height: 25px;
  text-align: center;
}

.img-container img {
  height: 100%;
}
